import React from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";


export default function Routes(props) {
  const location = useLocation();
  return (!props.isAuthenticated && location.pathname !== '/login' ? <Redirect to="/login" /> : 
  (
    <Switch>
        <Route exact path="/login">
          <Login isAuthenticated={ props.isAuthenticated } reloadApp={ props.reloadApp } passwordReset={ props.passwordReset } changeTitle={ props.changeTitle } />
        </Route>
        {/* Default. Leave at the end as it will match everything */}
        <Route>
          <Home isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle } />
        </Route>
    </Switch>
  ))
}