import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import AsyncStorage from '@react-native-community/async-storage';
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "./css/Login.css";
import "../App.css";
import Api from '../api.js';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      isAuthenticated: props.isAuthenticated
    };
    this.reloadApp = props.reloadApp
  }

  async componentDidMount(){
  }

  attemptLogin = async (data) => {
    const res = await Api.Chat.guest({username: this.state.username});
    console.log(res);
    if (res.error) alert(res.message);
    else{
      Api.saveToken(res.jwt);
      await AsyncStorage.setItem('userHasAuthenticated', 'true');
      await AsyncStorage.setItem('jwt', res.jwt);
      await AsyncStorage.setItem('userId', res.data.id);
      await AsyncStorage.setItem('userName', this.state.username);
      this.reloadApp(res.data, res.jwt);
      this.setState({isAuthenticated: true})
    }
  };

  submit = (event) => {
    event.preventDefault();
    try {
      this.attemptLogin();
    } catch (error) {
      console.log(error);
    }
  }

  validateForm = () => {
    return this.state.username.length > 0
  }

  render(){
    const { username } = this.state;
    return this.state.isAuthenticated ? (
      <Redirect to="/" />
    ) : (
      <>
      <div className="container login_container">
        <div className="row">
          <div className="col-sm">
            <h1 className="page_title">{ 'Enter your username' }</h1>
            <form onSubmit={this.submit} className="login_form">
              <FormGroup controlId="username">
                <FormLabel>Username</FormLabel>
                <FormControl
                  autoFocus
                  type="text"
                  className="login_input"
                  value={username}
                  onChange={e => this.setState({username: e.target.value})}
                />
              </FormGroup>
              <Button disabled={!this.validateForm()} type="submit">
                Enter Chat
              </Button>
            </form>
          </div>
        </div>
      </div>
      </>
    );  
  }
}

export default Login;