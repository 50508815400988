import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import "./App.css";
import Routes from "./Routes";
import AsyncStorage from '@react-native-community/async-storage';
import logo from './img/anywhere.png';
import Api from './api.js'
import jwt_decode from "jwt-decode";

class App extends Component {

  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      isAuthenticating: true,
      passwordReset: null,
      isAuthenticated: null,
      roles: [],
      title: 'Chat'
    };
  }

  async componentDidMount(){
    console.log('App.componentDidMount', await AsyncStorage.getItem('userHasAuthenticated'));
    const userHasAuthenticated = await AsyncStorage.getItem('userHasAuthenticated');
    const jwt = await AsyncStorage.getItem('jwt');
    if (userHasAuthenticated === 'true') {
      Api.saveToken(jwt);
      Api.setLogout(this.handleLogout);
      var decoded = jwt_decode(jwt);
      this.setState({ isAuthenticating: false, isAuthenticated: true, roles: decoded.UserRole ? decoded.UserRole.map(x => x.module) : [] })
    }
    else{
      try {
        await AsyncStorage.setItem('userHasAuthenticated', 'false');
        await AsyncStorage.setItem('jwt', null);
        await AsyncStorage.setItem('userId', null);
        await AsyncStorage.setItem('userName', null);
        this.setState({ isAuthenticating: false, isAuthenticated: false })
      } catch (e) {
        console.log('saving error', e)
      }
    }
  }

  handleLogout = async () => {
    console.log('handleLogout');
    try {
      await AsyncStorage.setItem('userHasAuthenticated', 'false');
      await AsyncStorage.setItem('jwt', null);
      await AsyncStorage.setItem('userId', null);
      await AsyncStorage.setItem('userName', null);
      this.setState({isAuthenticated: false})
    } catch (e) {
      console.log('saving error', e)
    }
  }

  reloadApp = (user, jwt) => {
    console.log('reloadApp', user);
    if (user && user !== null){
      var decoded = jwt_decode(jwt);
      this.setState({ isAuthenticated: true, roles: decoded.UserRole.map(x => x.module), passwordReset: null })
    }
  }

  changeTitle = (title) => {
    this.setState({title: title});
  }

  render(){
    return !this.state.isAuthenticating && (
      <div className="App">
        { this.state.isAuthenticated !== null && this.state.isAuthenticated ? (
            <header className="main_header">
              <div className="container page_header">
              <div className="logo_box">
                  <Link className="nav_item" to="/"><img className="logo" alt="Anywhere DATA logo" src={logo} /></Link>
                </div>
                <div className="titleBox">
                  <div className="titleItem">
                    {this.state.title}
                  </div>
                </div>
                <nav className="main_nav">
                  <NavLink className="nav_item" activeClassName="active" exact to="/"><i className="las la-globe"></i> <span>Home</span></NavLink>
                  <Link className="nav_item" to="/" onClick={ this.handleLogout }><i className="las la-sign-out-alt"></i> <span>Logout</span></Link>
                </nav>
              </div>
            </header>
          ) : (
            <>
              <header className="main_header">
                <img className="login_logo" alt="Login" src={logo} />
              </header>
            </>
          )
        }
        <Routes handleLogout={ this.handleLogout } reloadApp={ this.reloadApp } isAuthenticated={ this.state.isAuthenticated } passwordReset={ this.state.passwordReset } changeTitle={ this.changeTitle } />
      </div>
    );
  }
}

export default App;
