import { create } from 'apisauce'
const basepath = "https://chat.darthzejdr.eu/api/v1/";
const ChatURL = basepath + 'chat';

const apiChatInstance = create({
  baseURL: ChatURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default class Api {
  static token = undefined;

  static processError = (data, module) => {
    if(data && data.data && data.data.message === 'TokenExpiredError: jwt expired'){
      alert('Your session has expired. Please log back in.');
      this.logout();
      return {error: true, message: 'Your session has expired. Please log back in.'};
    }
    else if (data && data.data && data.data.message === 'ERROR' && data.data.data === 'ACCESS_DENIED'){
      return {error: true, message: 'Nemate ovlasti pristupiti ovoj ruti. Molimo da se javite Anywhere podršci (support@anywhere.hr).'};
    }
    else if (data && data.data && data.data.message === 'ERROR' && data.data.data === 'LIMIT_REACHED'){
      return {error: true, message: 'Dostigli ste limit prikaza dokumenata. I dalje možete vidjeti dokumente koje ste već otvarali. Za povećanje limita javite se Anywhere podršci (support@anywhere.hr).'};
    }
    else if (data && data.data && data.data.message && data.data.message.indexOf("request.body.text should NOT be shorter than 3 characters, request.body should have required property 'zk_ulozak', request.body should match some schema in anyOf") > -1){
      return {error: true, message: 'Molimo unesite barem 3 znaka ili zemljišnoknjižni uložak.'}
    }
    else if (data && data.data && data.data.message && data.data.message.indexOf("request.body.text should NOT be shorter than 3 characters, request.body should have required property 'posjedovni_list', request.body should match some schema in anyOf") > -1){
      return {error: true, message: 'Molimo unesite barem 3 znaka ili posjedovni list.'}
    }
    else if (data && data.data && data.data.message && data.data.message.indexOf('request.body.text should NOT be shorter than 3 characters') > -1){
      return {error: true, message: 'Molimo unesite barem 3 znaka.'}
    }
    else if (data && data.data && data.data.message && data.data.message === 'ERROR'){
      return {error: true, message: data.data.data}
    }
    else {
      return {error: true, message: 'Unable to communicate to "' + module + '" Backend'}
    }
  }

  static saveToken = (token) => {
    console.log('Saving token to Api');
    apiChatInstance.setHeader('Authorization', `Bearer ${token}`);
    this.token=token;
  }

  static setLogout = (logout) => {
    console.log('Adding logout to Api');
    this.logout = logout;
  }

  static Chat = {
  guest: async (data) => {
    const res = await apiChatInstance.post('/guest', data);
    return res.ok ? res.data : this.processError(res, 'Chat');
  },
  checkProtected: async () => {
    const res = await apiChatInstance.get('/check_protected');
    return res.ok ? res.data : this.processError(res, 'Chat');
  }
  }
}
