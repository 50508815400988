import React, { Component } from 'react';
import Loader from "../components/Loader.js";
import AsyncStorage from '@react-native-community/async-storage';
import socketio from "socket.io-client";
import { Peer } from "peerjs";
import { Button, FormControl, Row, Col } from "react-bootstrap";
import Api from '../api.js'
import ReactPlayer from 'react-player'

// TODO: Change answer to a modal with enable audio/video/screen stream
// TODO: Show what the other person is sharing in users section

// TODO: change stream (Probably end call and start new one). Could also be done using dummy stream, 
// but can't do it for screenshare and webcam. Maybe use dummy webcam and change stream for that, 
// and end and restart call for screen stream

// TODO: add heartbeat to detect when the other party closes the page

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      username: null,
      jwt: null,
      peerId: null,
      hasWebCam: false,
      hasMicrophone: false,
      hasSpeakers: false,
      isMicrophoneAlreadyCaptured: false,
      isWebcamAlreadyCaptured: false,
      users: [],
      message: '',
      messages: [],
      calls: [],
      activeCalls: [],
      perms: [],
      streams: [],
      conns: [],
      isAuthenticated: props.isAuthenticated,
      audioStream: null,
      videoStream: null,
      screenStream: null,
    };
    this.handleLogout = props.handleLogout;
    this.socket = null;
    this.peer = null;

    let silence = () => {
      let ctx = new AudioContext(), oscillator = ctx.createOscillator();
      let dst = oscillator.connect(ctx.createMediaStreamDestination());
      oscillator.start();
      return Object.assign(dst.stream.getAudioTracks()[0], {enabled: false});
    }
    
    let black = ({width = 640, height = 480} = {}) => {
      let canvas = Object.assign(document.createElement("canvas"), {width, height});
      canvas.getContext('2d').fillRect(0, 0, width, height);
      let stream = canvas.captureStream();
      return Object.assign(stream.getVideoTracks()[0], {enabled: false});
    }
    
    let blackSilence = (...args) => new MediaStream([black(...args), silence()]);
    this.emptyStream = blackSilence();
    this.beatTimer = null;
    props.changeTitle('Chat');
  }

  async componentDidMount(){
    this.setState(await this.checkPermissionStatus());
    const userHasAuthenticated = await AsyncStorage.getItem('userHasAuthenticated');
    if (userHasAuthenticated === 'true' && this.state.isAuthenticated) this.checkBackend();
    this.setState({username : await AsyncStorage.getItem('userName'), jwt : await AsyncStorage.getItem('jwt')});
    this.socket = socketio.connect('https://chat.darthzejdr.eu');
    this.socket.on('jwt?', () => { console.log('socket', 'on', 'jwt?'); this.socket.emit('jwt', this.state.jwt); });
    this.socket.on('peerid?', () => { console.log('socket', 'on', 'peerid?'); this.socket.emit('peerid', this.state.peerId); });
    this.socket.on('peerid_ok', peerid => { console.log('socket', 'on', 'peerid_ok', peerid); });
    this.socket.on('users', users => {
      this.setState({users: users});
      users.forEach(user => user.peerId && user.peerId !== this.state.peerId && this.establishConn(user.peerId));
    });
    this.peer = new Peer({ host: 'chat.darthzejdr.eu', secure: true, path: '/peerjs', token: this.state.jwt });
    this.peer.on('open', (id) => { console.log('peer', 'open', id); this.setState({ peerId: id}); this.socket !== null && this.socket.emit('peerid', id); });
    this.peer.on('error', (error) => { console.log('peer', 'error', error); this.setState({ peerId: null }); this.socket !== null && this.socket.emit('peerid', null); });
    this.peer.on('call', (call) => this.incomingCall(call));
    this.peer.on('connection', (conn) => this.incomingConn(conn));
    //this.beatTimer = window.setInterval(() => this.heartbeat(), 1000);
    this.setState({isLoaded: true});
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
    this.socket && this.socket.close();
    this.beatTimer && window.clearInterval(this.beatTimer);
  }

  checkPermissionStatus = async () => {
    return new Promise((resolve, reject) => {
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        // Firefox 38+ seems having support of enumerateDevicesx
        navigator.enumerateDevices = function(callback) {
          navigator.mediaDevices.enumerateDevices().then(callback);
        };
      }
    
      var MediaDevices = [];
      var isHTTPs = window.location.protocol === 'https:';
      var canEnumerate = false;
      
      if (typeof MediaStreamTrack !== 'undefined' && 'getSources' in MediaStreamTrack) {
        canEnumerate = true;
      } else if (navigator.mediaDevices && !!navigator.mediaDevices.enumerateDevices) {
        canEnumerate = true;
      }
      
      var hasMicrophone = false;
      var hasSpeakers = false;
      var hasWebcam = false;
      
      var isMicrophoneAlreadyCaptured = false;
      var isWebcamAlreadyCaptured = false;
      
      function checkDeviceSupport(callback) {
        if (!canEnumerate) {
          return;
        }
      
        if (!navigator.enumerateDevices && window.MediaStreamTrack && window.MediaStreamTrack.getSources) {
          navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(window.MediaStreamTrack);
        }
      
        if (!navigator.enumerateDevices && navigator.enumerateDevices) {
          navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
        }
      
        if (!navigator.enumerateDevices) {
          if (callback) {
            callback();
          }
          return;
        }
      
        MediaDevices = [];
        navigator.enumerateDevices(function(devices) {
          devices.forEach(function(_device) {
            var device = {};
            for (var d in _device) {
              device[d] = _device[d];
            }
      
            if (device.kind === 'audio') {
              device.kind = 'audioinput';
            }
      
            if (device.kind === 'video') {
              device.kind = 'videoinput';
            }
      
            var skip;
            MediaDevices.forEach(function(d) {
              if (d.id === device.id && d.kind === device.kind) {
                skip = true;
              }
            });
      
            if (skip) {
              return;
            }
      
            if (!device.deviceId) {
              device.deviceId = device.id;
            }
      
            if (!device.id) {
              device.id = device.deviceId;
            }
      
            if (!device.label) {
              device.label = 'Please invoke getUserMedia once.';
              if (!isHTTPs) {
                device.label = 'HTTPs is required to get label of this ' + device.kind + ' device.';
              }
            } else {
              if (device.kind === 'videoinput' && !isWebcamAlreadyCaptured) {
                isWebcamAlreadyCaptured = true;
              }
      
              if (device.kind === 'audioinput' && !isMicrophoneAlreadyCaptured) {
                isMicrophoneAlreadyCaptured = true;
              }
            }
      
            if (device.kind === 'audioinput') {
              hasMicrophone = true;
            }
      
            if (device.kind === 'audiooutput') {
              hasSpeakers = true;
            }
      
            if (device.kind === 'videoinput') {
              hasWebcam = true;
            }

            console.log(device.kind);
      
            // there is no 'videooutput' in the spec.
      
            MediaDevices.push(device);
          });
      
          if (callback) {
            callback();
          }
        });
      }
    
      // check for microphone/camera support!
      checkDeviceSupport(function() {
        resolve({hasWebCam: hasWebcam, hasMicrophone: hasMicrophone, hasSpeakers: hasSpeakers, isMicrophoneAlreadyCaptured: isMicrophoneAlreadyCaptured, isWebcamAlreadyCaptured: isWebcamAlreadyCaptured});
      })
    })
  }

  checkBackend = async () => {
    const checkProtected = await Api.Chat.checkProtected();
    if (checkProtected.error) alert(checkProtected.message);
    else console.log(checkProtected);
  }

  async setUpAudio(){
    try{
      let audioStream = await navigator.mediaDevices.getUserMedia({audio:true});
      audioStream.addEventListener('inactive', () => {this.closeAudio()});
      this.setState({audioStream: audioStream});
    }
    catch(err){
      console.log(err);
    }
    this.setState(await this.checkPermissionStatus());
    this.permChanged();
  }

  closeAudio(){
    console.log(this.state.audioStream);
    this.state.audioStream && this.state.audioStream.getTracks().forEach(function(track) {track.stop();});
    this.setState({audioStream: null});
    this.permChanged();
  }

  async setUpVideo(){
    try{
      let videoStream = await navigator.mediaDevices.getUserMedia({video:true});
      videoStream.addEventListener('inactive', () => {this.closeVideo()});
      this.setState({videoStream: videoStream});
    }
    catch(err){
      console.log(err);
    }
    this.setState(await this.checkPermissionStatus());
    this.permChanged();
  }

  closeVideo(){
    console.log(this.state.videoStream);
    this.state.videoStream && this.state.videoStream.getTracks().forEach(function(track) {track.stop();});
    this.setState({videoStream: null});
    this.permChanged();
  }

  async setUpScreenShare(){
    try{
      let screenStream = await navigator.mediaDevices.getDisplayMedia({audio: true, video: true});
      screenStream.addEventListener('inactive', () => {this.closeScreenShare()});
      this.setState({screenStream: screenStream});
    }
    catch(err){
      console.log(err);
    }
    this.setState(await this.checkPermissionStatus());
    this.permChanged();
  }

  closeScreenShare(){
    console.log(this.state.screenStream);
    this.state.screenStream && this.state.screenStream.getTracks().forEach(function(track) {track.stop();});
    this.setState({screenStream: null});
    this.permChanged();
  }

  establishConn(peerId){
    console.log('establishConn', this.state.conns.filter(conn => conn.peer === peerId).length > 0 ? 'Already established' : 'establishing connection', peerId);
    if (this.state.conns.filter(conn => conn.peer === peerId).length === 0){
      var conn = this.peer.connect(peerId);
      this.setState({conns: [...this.state.conns.filter(connx => connx.peer !== conn.peer), conn]});
      conn.on('open', () => { conn.send({system: 'init?'}); });
      conn.on('data', data => this.connData(conn, data));
      conn.on('close', () => this.closedConn(conn));
    }
  }

  incomingConn(inConn){
    console.log('incomingConn', this.state.conns.filter(conn => conn.peer === inConn.peer).length > 0 ? 'Already established' : 'establishing connection', inConn.peer);
    this.setState({conns: [...this.state.conns.filter(conn => conn.peer !== inConn.peer), inConn]});
    inConn.on('open', () => { inConn.send({system: 'init?'}); });
    inConn.on('data', data => this.connData(inConn, data));
    inConn.on('close', () => this.closedConn(inConn));
  }

  connData(conn, data){
    console.log('connData', conn.peer, data);
    if (data.system && data.system === 'init?') conn.send({system: 'init'});
    else if (data.system && data.system === 'chat') {
      let user = this.state.users.filter(user => user.peerId === conn.peer).length > 0 ? this.state.users.filter(user => user.peerId === conn.peer)[0].username : 'N/A';
      let time = new Date();
      time = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0') + ':' + time.getSeconds().toString().padStart(2, '0');
      this.setState({messages: [...this.state.messages, time + ' | ' + user + ': ' + data.message]});
      this.scrollToBottom(); 
    }
    else if (data.system && data.system === 'permUpdate') this.setState({perms: [...this.state.perms.filter(perm => perm.peerId !== data.perms.peerId), data.perms]})
    else if (data.system && data.system === 'callEnded'){
      this.state.calls.filter(call => call.peer === conn.peer).forEach(call => call.close());
      this.setState({
        calls: this.state.calls.filter(callInternal => callInternal.peer !== conn.peer),
        activeCalls: this.state.activeCalls.filter(callInternal => callInternal !== conn.peer)
      });
    }
    else if (data.system && data.system === 'callChanged'){
      if (this.state.calls.filter(call => call.peer === conn.peer).length > 0){
        this.state.calls.filter(call => call.peer === conn.peer).forEach(call => call.close());
        this.setState({
          calls: this.state.calls.filter(callInternal => callInternal.peer !== conn.peer)
        });
      }
    }
    else console.log('connData unknown', conn.peer, data);
  }

  closedConn(conn){
    console.log('closedConn', conn.peer);
    this.setState({
      calls: this.state.calls.filter(call => call.peer !== conn.peer),
      activeCalls: this.state.activeCalls.filter(call => call !== conn.peer),
      streams: this.state.streams.filter(stream => stream.peerId !== conn.peer),
      perms: this.state.perms.filter(perm => perm.peerId !== conn.peer),
      conns: this.state.conns.filter(connInternal => connInternal.peer !== conn.peer)
    });
  }

  validateMessageForm = () => {
    return this.state.message.length > 0
  }

  submitMessage = (event) => {
    event.preventDefault();
    this.sendMessage();
  }

  sendMessage(){
    let time = new Date();
    time = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0') + ':' + time.getSeconds().toString().padStart(2, '0');
    this.setState({messages: [...this.state.messages, time + ' | ' + this.state.username + ': ' + this.state.message]});
    this.scrollToBottom(); 
    this.state.conns.forEach(conn => conn.send({system: 'chat', message: this.state.message}));
    this.setState({message: ''});
  } 

  scrollToBottom() {
    this.el.scrollIntoView({ behavior: 'smooth' });
  }

  permChanged(){
    this.state.conns.forEach(conn => {
      conn.send({
        system: 'permUpdate',
        perms: {
          peerId: this.state.peerId,
          audio: this.state.audioStream && this.state.audioStream.getAudioTracks().map(track => track.id)[0],
          video: this.state.videoStream && this.state.videoStream.getVideoTracks().map(track => track.id)[0],
          screenAudio: this.state.screenStream && this.state.screenStream.getAudioTracks().map(track => track.id)[0],
          screenVideo: this.state.screenStream && this.state.screenStream.getVideoTracks().map(track => track.id)[0]
        }
      });
    })
    this.state.conns.forEach(conn => this.state.calls.filter(call => call.peer === conn.peer).length > 0 && conn.send({system: 'callChanged'}));
    this.state.calls.forEach(async call => {
      await call.close();
      this.call(call.peer);
    })
  }

  async call(peerId){
    try {
      if (this.state.audioStream === null && this.state.videoStream === null && this.state.screenStream === null) alert('Please capture at least one device');
      else{
        let stream = new MediaStream();
        if (this.state.audioStream !== null) stream.addTrack(this.state.audioStream.getAudioTracks()[0]);
        if (this.state.videoStream !== null) stream.addTrack(this.state.videoStream.getVideoTracks()[0]);
        if (this.state.screenStream !== null) stream.addTrack(this.state.screenStream.getVideoTracks()[0]);
        if (this.state.screenStream !== null && this.state.screenStream.getAudioTracks().length>0) stream.addTrack(this.state.screenStream.getAudioTracks()[0]);
        let call = this.peer.call(peerId, stream);
        call.on('stream', (stream) => this.call_on_stream(stream, peerId));
        call.on('close', () => {
          console.log('call closed');
          this.setState({
            calls: this.state.calls.filter(callInternal => callInternal.peer !== call.peer)
          });
        });
        this.setState({
          calls: [...this.state.calls.filter(callInternal => callInternal.peer !== call.peer), call],
          activeCalls: [...this.state.activeCalls.filter(callInternal => callInternal !== call.peer), call.peer]
        });
      }
    } catch (error) {
      console.log(error);
      alert('Call failed');
    }
  }

  async incomingCall(call){
    console.log('peer', 'call', call);
    console.log(this.state.calls);
    let currentCall = this.state.activeCalls.filter(callx => callx === call.peer);
    console.log('currentCall', currentCall);
    let confirmResponse = currentCall.length > 0 || window.confirm("Incoming call. Do you want to accept it?");
    if(confirmResponse){
      let stream = new MediaStream();
      if (this.state.audioStream !== null) stream.addTrack(this.state.audioStream.getAudioTracks()[0]);
      if (this.state.videoStream !== null) stream.addTrack(this.state.videoStream.getVideoTracks()[0]);
      if (this.state.screenStream !== null) stream.addTrack(this.state.screenStream.getVideoTracks()[0]);
      if (this.state.screenStream !== null && this.state.screenStream.getAudioTracks().length>0) stream.addTrack(this.state.screenStream.getAudioTracks()[0]);
      call.answer(stream);
      call.on('stream', (stream) => this.call_on_stream(stream, call.peer));
      call.on('close', () => this.call_closed(call.peer));
      this.setState({
        calls: [...this.state.calls.filter(callInternal => callInternal.peer !== call.peer), call],
        activeCalls: [...this.state.activeCalls.filter(callInternal => callInternal !== call.peer), call.peer]
      });
    }
    else{
      console.log();
      this.state.conns.filter(conn => conn.peer === call.peer).forEach(conn => conn.send({system: 'callEnded'}));
      call.close();
      console.log("Incoming call closed.");
    }
  }

  call_on_stream(stream, peerId){
    console.log('call_on_stream');
    if (this.state.streams.filter(existingStream => existingStream.id === stream.id).length === 0){
      stream.peerId=peerId;
      this.setState({streams: [...this.state.streams.filter(streamInternal => streamInternal.peerId !== stream.peerId), stream]});
    }
  }

  call_closed(peerId){
    console.log('call closed', peerId);
    this.setState({
      calls: this.state.calls.filter(callInternal => callInternal.peer !== peerId),
      // activeCalls: this.state.activeCalls.filter(callInternal => callInternal !== peerId),
      streams: this.state.streams.filter(stream => stream.peerId !== peerId)      
    });
  }

  endCall(peerId){
    this.state.calls.filter(call => call.peer === peerId).forEach(call => call.close());
    this.state.conns.filter(conn => conn.peer === peerId).forEach(conn => conn.send({system: 'callEnded'}));
    this.setState({
      calls: this.state.calls.filter(call => call.peer !== peerId),
      activeCalls: this.state.activeCalls.filter(call => call !== peerId),
      streams: this.state.streams.filter(stream => stream.peerId !== peerId),
    });
  }

  // -const video = () => {
  //   -  calls.forEach(call => {
  //   -    let senders = call.peerConnection.getSenders();
  //   -    console.log('senders', senders);
  //   -    console.log('avStream', avStream, avStream.getTracks());
  //   -    if (document.getElementById('video').checked) senders[1].replaceTrack(avStream.getVideoTracks()[0]).then(asdf => console.log('asdf', asdf)).catch(asdf2 => console.log('asdf2', asdf2));
  //   -    else  senders[1].replaceTrack(emptyVideoTrack);
  //   -    //console.log(call, senders);
  //   -    //connectToPeer(call.peer)
  //   -  });
  //   -}

  // //some things that should be repeatedly checked, would be better if i could do then on active change
  // heartbeat(){
  //   // if (this.state.audioStream !== null && !this.state.audioStream.active) this.closeAudio();
  //   // if (this.state.videoStream !== null && !this.state.videoStream.active) this.closeVideo();
  //   // if (this.state.screenStream !== null && !this.state.screenStream.active) this.closeScreenShare();
  //   // console.log('beat', this.state.audioStream, this.state.videoStream, this.state.screenStream);
  //   // //if
  // }

  // renderRightsTable(){
  //   return <table border={1} cellPadding={5}>
  //     <tbody>
  //       <tr>
  //         <td>Status</td>
  //         <td>Availability</td>
  //         <td>Permissions</td>
  //         <td>Enable/Disable</td>
  //         <td>Enabled</td>
  //       </tr>
  //       <tr>
  //         <td>Microphone</td>
  //         <td style={{textAlign: 'center'}}><i className={this.state.hasMicrophone ? "las la-microphone" : "las la-microphone-slash"}></i></td>
  //         <td style={{textAlign: 'center'}}><i className={this.state.isMicrophoneAlreadyCaptured ? "las la-microphone" : "las la-microphone-slash"}></i></td>
  //         <td style={{textAlign: 'center'}}>
  //           {this.state.hasMicrophone && (
  //             this.state.audioStream === null ? 
  //             <Button onClick={() => {this.setUpAudio();}}>{this.state.isMicrophoneAlreadyCaptured ? 'Capture' : 'Allow and capture'}</Button> :
  //             <Button onClick={() => {this.closeAudio();}}>Close</Button>
  //           )}</td>
  //         <td style={{textAlign: 'center'}}>{this.state.audioStream && <i className="las la-check"></i>}</td>
  //       </tr>
  //       <tr>
  //         <td>WebCam</td>
  //         <td style={{textAlign: 'center'}}><i className={this.state.hasWebCam ? "las la-video" : "las la-video-slash"}></i></td>
  //         <td style={{textAlign: 'center'}}><i className={this.state.isWebcamAlreadyCaptured ? "las la-video" : "las la-video-slash"}></i></td>
  //         <td style={{textAlign: 'center'}}>
  //           {this.state.hasWebCam && (
  //             this.state.videoStream === null ? 
  //             <Button onClick={() => {this.setUpVideo();}}>{this.state.isWebcamAlreadyCaptured ? 'Capture' : 'Allow and capture'}</Button> : 
  //             <Button onClick={() => {this.closeVideo();}}>Close</Button>
  //           )}
  //         </td>
  //         <td style={{textAlign: 'center'}}>{this.state.videoStream && <i className="las la-check"></i>}</td>
  //       </tr>
  //       <tr>
  //         <td>Screen share</td>
  //         <td style={{textAlign: 'center'}}></td>
  //         <td style={{textAlign: 'center'}}></td>
  //         <td style={{textAlign: 'center'}}>
  //           {
  //             this.state.screenStream === null ? 
  //             <Button onClick={() => {this.setUpScreenShare();}}>Capture</Button> :
  //             <Button onClick={() => {this.closeScreenShare();}}>Close</Button>
  //           }
  //         </td>
  //         <td style={{textAlign: 'center'}}>{this.state.screenStream && <i className="las la-check"></i>}</td>
  //       </tr>
  //       <tr>
  //       </tr>
  //     </tbody>
  //   </table>
  // }

  renderChat(){
    return <>
      <tr>
        <td>
          <form onSubmit={this.submitMessage} className="message_form">
            <Row>
              <Col>
                <FormControl
                  autoFocus
                  type="text"
                  className="message_input"
                  value={this.state.message}
                  onChange={e => this.setState({message: e.target.value})}
                />
              </Col>
              <Col xs="auto">
                <Button disabled={!this.validateMessageForm()} type="submit">
                  Send
                </Button>
              </Col>
              <Col xs="auto">{this.state.hasMicrophone ? (this.state.audioStream ? 
                <Button onClick={() => {this.closeAudio();}}><i className="las la-microphone-slash"></i></Button> : 
                <Button onClick={() => {this.setUpAudio();}}><i className="las la-microphone"></i></Button>) : 
                <Button className='btn-danger' disabled={true}><i className="las la-microphone"></i></Button>
              }</Col>
              <Col xs="auto">{this.state.hasWebCam ? (this.state.videoStream ? 
                <Button onClick={() => {this.closeVideo();}}><i className="las la-video-slash"></i></Button> : 
                <Button onClick={() => {this.setUpVideo();}}><i className="las la-video"></i></Button>):
                <Button className='btn-danger' disabled={true}><i className="las la-video"></i></Button>
              }</Col>
              <Col xs="auto">{this.state.screenStream ? 
                <Button onClick={() => {this.closeScreenShare();}}><i className="las la-window-close"></i></Button> : 
                <Button onClick={() => {this.setUpScreenShare();}}><i className="las la-desktop"></i></Button>}
              </Col>
            </Row>
          </form>
        </td>
        <td xs="auto">
          Users
        </td>
      </tr>
      <tr>
        <td>
          <div style={{ height: 300, maxHeight: 300, overflow: 'scroll' }}>
            { this.state.messages.map((message, i) => <span key={i}>{message}<br/></span>) }
            <div ref={el => { this.el = el; }} />
          </div>
        </td>
        <td>
          { 
            this.state.users.map((user, i) => <Row key={i}>
              <Col style={{margin: 'auto'}}>
                {user.username}
                {user.peerId !== this.state.peerId ? this.state.perms.filter(call=>call.peerId===user.peerId).map((call, j) => <React.Fragment key={j}>
                  {call.audio && <i className='las la-microphone'></i>}
                  {call.video && <i className='las la-video'></i>}
                  {call.screenVideo && <i className='las la-desktop'></i>}
                  {call.screenAudio && <i className='las la-volume-up'></i>}
                </React.Fragment>) : (<React.Fragment key={-1}>
                  {this.state.audioStream && <i className='las la-microphone'></i>}
                  {this.state.videoStream && <i className='las la-video'></i>}
                  {this.state.screenStream && <i className='las la-desktop'></i>}
                  {this.state.screenStream && this.state.screenStream.getAudioTracks().length > 0 && <i className='las la-volume-up'></i>}
                </React.Fragment>)}
              </Col>
              {user.peerId !== this.state.peerId ? (this.state.calls.filter(call=>call.peer===user.peerId).length===0 ? (
                <Col xs="auto"><Button onClick={() => {this.call(user.peerId)}}><i className="las la-phone"></i></Button></Col>
              ) : (
                <Col xs="auto"><Button onClick={() => {this.endCall(user.peerId)}}><i className="las la-phone-slash"></i></Button></Col>
              )) : ''}
            </Row>) 
          }
        </td>
      </tr>
    </>
  }

  renderLocalStreams(){
    let newStream = null;
    if (this.state.videoStream && this.state.audioStream){
      newStream = new MediaStream();
      newStream.addTrack(this.state.audioStream.getAudioTracks()[0]);
      newStream.addTrack(this.state.videoStream.getVideoTracks()[0]);
    }

    return <tr>
      <td colSpan={2}>
        {this.state.audioStream && !this.state.videoStream && <Col>
          <ReactPlayer controls={true} width={300} height={50} url={this.state.audioStream} muted={true} playing={true} />
        </Col>}
        {!this.state.audioStream && this.state.videoStream && <Col>
          <ReactPlayer controls={true} width={300} height={200} url={this.state.videoStream} muted={true} playing={true} />
        </Col>}
        {this.state.audioStream && this.state.videoStream && <Col>
          <ReactPlayer controls={true} width={300} height={200} url={newStream} muted={true} playing={true} />
        </Col>}
        {this.state.screenStream && <Col>
          <ReactPlayer controls={true} width={300} height={200} url={this.state.screenStream} muted={true} playing={true} />
        </Col>}
        {!this.state.audioStream && !this.state.videoStream && !this.state.screenStream && <Col>Please capture the devices you wish to use in the conversation. Then you can start a call. Should also set this up to receive calls.</Col>}
      </td>
    </tr>
  }

  renderRemoteStreams(){
    return <>
      <tr>
        <td colSpan={2}>
          {
            this.state.streams.map((stream, i) => {
              let perms = this.state.perms.filter(inc => inc.peerId === stream.peerId);
              if (perms.length > 0) perms = perms[0];
              else perms = null;
              if (perms){
                let audioTracks = stream.getAudioTracks();
                let videoTracks = stream.getVideoTracks();
                let audioTrack = null;
                let videoTrack = null;
                let screenAudioTrack = null;
                let screenVideoTrack = null;
                audioTracks.forEach(track => {
                  if (track.id === perms.screenAudio) screenAudioTrack = track;
                  else audioTrack = track;
                })
                videoTracks.forEach(track => {
                  if (track.id === perms.screenVideo) screenVideoTrack = track;
                  else videoTrack = track;
                })

                let stream1 = new MediaStream();
                let stream2 = new MediaStream();
                
                audioTrack && stream1.addTrack(audioTrack);
                videoTrack && stream1.addTrack(videoTrack);
                screenAudioTrack && stream2.addTrack(screenAudioTrack);
                screenVideoTrack && stream2.addTrack(screenVideoTrack);

                return <Row key={i}>
                  {(audioTrack || videoTrack) && <Col key={1}><ReactPlayer controls={true} width={300} height={videoTrack ? 200 : 50} url={stream1} muted={false} playing={true} /></Col>}
                  {(screenAudioTrack || screenVideoTrack) && <Col key={2}><ReactPlayer controls={true} width={300} height={screenVideoTrack ? 200 : 50} url={stream2} muted={false} playing={true} /></Col>}
                </Row>
              }
              else return '';
            })
          }
        </td>
      </tr>
    </>
  }

  render(){
    // if (this.state.audioStream && this.state.videoStream) this.state.videoStream.addTrack(this.state.audioStream.getAudioTracks()[0]);
    // console.log('perms', this.state.perms);
    // console.log('tracks', this.state.streams.map(stream => stream.getTracks()));
    // console.log('audioStreamTracks', this.state.audioStream && this.state.audioStream.getTracks());
    // console.log('videoStream', this.state.videoStream);
    // console.log('videoStreamTracks', this.state.videoStream && this.state.videoStream.getTracks());
    // console.log('screenStream', this.state.screenStream);
    // console.log('screenStreamTracks', this.state.screenStream && this.state.screenStream.getTracks());
    console.log('conns', this.state.conns);
    console.log('calls', this.state.calls);
    console.log('activeCalls', this.state.activeCalls);
    

    return (
      <div className="container page_container">
          { !this.state.isLoaded ? <Loader></Loader> : (
            <>
            <div>
              <table border="1" width="100%">
                <tbody>
                  {/* <tr>
                    <td colSpan={2}>
                      <Row>
                        <Col>
                          {this.renderRightsTable()}
                        </Col>
                          {this.renderLocalStreams()}
                      </Row>
                    </td>
                  </tr> */}
                  {this.renderChat()}
                  {this.renderLocalStreams()}
                  {this.renderRemoteStreams()}
                </tbody>
              </table>
            </div>
          </> )}
      </div>
    );
  }

}

export default Home;